function BufferLoader(context, tracks, callback) {
    this.context = context
    this.tracks = tracks
    this.onload = callback
    this.bufferList = new Array()
    this.loadCount = 0
}

BufferLoader.prototype.loadBuffer = function(track, index) {
    // Load buffer asynchronously
    var request = new XMLHttpRequest()
    request.open('GET', track.url, true)
    request.responseType = 'arraybuffer'

    var loader = this

    request.onload = function() {
        // Asynchronously decode the audio file data in request.response
        loader.context.decodeAudioData(request.response, function(buffer) {
            if (! buffer) {
                console.error('error decoding file data: ' + track.url)

                return
            }

            loader.bufferList[index] = { buffer, track }

            if (++loader.loadCount == loader.tracks.length) {
                loader.onload(loader.bufferList)
            }
        },

        function(error) {
            console.error('decodeAudioData error', error)
        })
    }

    request.onerror = function() {
        console.error('BufferLoader: XHR error')
    }

    request.send()
}

BufferLoader.prototype.load = function() {
    for (var i = 0; i < this.tracks.length; ++i) {
        this.loadBuffer(this.tracks[i], i)
    }
}

export default BufferLoader
