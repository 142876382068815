<template>
  <div class="text-white mt-8">
    <div v-if="loaded" class="container mx-auto text-center">
      <div class="text-center">
        <router-link :to="{ name: 'home' }" class="inline-flex bg-gray-900 text-white p-2 rounded">Home</router-link>
      </div>
      <h2 class="mt-8 px-8 text-2xl font-semibold">{{ show.name }}</h2>
      <div class="mt-6">
        <div class="px-8">
          <div class="text-pink-500 inline-block relative w-full bg-white rounded-lg">
            <select v-model="activeTrack" class="block appearance-none w-full rounded-lg px-4 py-3 pr-8 rounded-sm leading-tight focus:outline-none focus:shadow-outline">
              <option disabled :value="null">-- Select a Song --</option>
              <option v-for="track in tracks" :key="track.id" :value="track.id">{{ track.title }}</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"/>
              </svg>
            </div>
          </div>
        </div>

        <div v-if="activeTrack" class="mt-12">
          <h1 class="text-4xl font-semibold">{{ activeTrackLabel }}</h1>

          <div v-if="hasTrackLoaded">
            <div class="flex justify-center items-center space-x-12 mt-8">
              <button type="button" class="text-pink-200 focus:outline-none" @click.prevent="rewind">
                <rewind-button class="w-16 h-16 fill-current"></rewind-button>
              </button>

              <button type="button" class="text-white focus:outline-none" @click.prevent="playing ? pause() : play()">
                <play-button v-if="! playing" class="w-16 h-16 fill-current"></play-button>
                <pause-button v-else class="w-16 h-16 fill-current"></pause-button>
              </button>
            </div>

            <p class="mt-4 text-sm">{{ playbackPositionLabel }}</p>

            <div class="flex justify-center items-center space-x-12 mt-8">
              <button
                  v-if="activeTrackHasClick"
                  @click.prevent="clickEnabled ? setClick(0) : setClick(0.75)"
                  type="button"
                  class="rounded-full w-16 h-16 border border-yellow-400 focus:outline-none font-medium uppercase text-sm"
                  :class="[ clickEnabled ? 'bg-yellow-400 text-white' : 'text-yellow-400 bg-transparent' ]"
              >Click</button>

              <button
                  v-if="activeTrackHasMelody"
                  @click.prevent="melodyEnabled ? setMelody(0) : setMelody(2.0)"
                  type="button"
                  class="rounded-full w-16 h-16 border border-purple-900 focus:outline-none font-medium uppercase text-sm"
                  :class="[ melodyEnabled ? 'bg-purple-900 text-white' : 'text-purple-900 bg-transparent' ]"
              >Melody</button>
            </div>

            <div class="mt-6">
              <a :href="activeTrackMainFileURL" target="_blank" class="bg-gray-800 text-blue-100 px-3 py-2 rounded text-xs uppercase">Download</a>
            </div>
          </div>

          <div v-else class="mt-4 px-12 md:px-48">
            <div class="bg-white text-pink-500 px-12 py-2 rounded-full w-full">
              Loading song...
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="container mx-auto text-center">
      <h1 class="text-5xl font-semibold">Loading...</h1>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import shows from '@/shows.js'
import BufferLoader from '../BufferLoader.js'
const unmuteAudio = require('unmute-ios-audio')

import PauseButton from '@/components/buttons/Pause.vue'
import PlayButton from '@/components/buttons/Play.vue'
import RewindButton from '@/components/buttons/Rewind.vue'

export default {
  name: 'Show',

  components: {
    PauseButton,
    PlayButton,
    RewindButton,
  },

  data() {
    return {
      loaded: false,
      show: null,

      playbackPositionLabel: '',
      context: null,

      trackSource: null,
      trackGainNode: null,

      clickSource: null,
      clickGainNode: null,

      melodySource: null,
      melodyGainNode: null,

      oldTrackBuffer: null,
      oldClickBuffer: null,

      bufferLoader: null,

      clickEnabled: false,
      melodyEnabled: false,
      paused: false,
      playing: false,
      startedAt: 0,
      pausedAt: 0,
      trackLength: 0,
      offset: 0,
      tracks: null,
      activeTrack: null,
      hasTrackLoaded: false,
    }
  },

  async mounted() {
    this.show = shows.find(_ => _.slug === this.$route.params.slug)

    unmuteAudio()
    await this.getTracks()
    this.initAudioEngine()
  },

  watch: {
    activeTrack(val) {
      if (val != null) {
        if (this.playing) {
          this.stop()
        }

        this.initAudioEngine()
        this.loadSelectedTrack(val)
      }
    }
  },

  computed: {
    activeTrackLabel() {
      if (this.activeTrack == null) {
        return ''
      }

      return this.tracks.find(_ => _.id === this.activeTrack).title
    },

    activeTrackObject() {
      if (this.activeTrack == null) {
        return null
      }

      return this.tracks.find(_ => _.id === this.activeTrack)
    },

    activeTrackHasClick() {
      if (this.activeTrackObject == null) {
        return false
      }

      return this.activeTrackObject.files.some(_ => _.name === 'click')
    },

    activeTrackHasMelody() {
      if (this.activeTrackObject == null) {
        return false
      }

      return this.activeTrackObject.files.some(_ => _.name === 'melody')
    },

    activeTrackMainFileURL() {
      if (this.bufferLoader != null && this.bufferLoader.tracks.length) {
        return this.bufferLoader.tracks.find(_ => _.name === 'track').url
      }

      return null
    },
  },

  methods: {
    uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },

    async getTracks() {
      const tracklistURL = process.env.VUE_APP_ENV == 'local' ? `/tracks/${this.show.slug}/list.json` : `https://tracks.mybandpage.com/${this.show.slug}/list.json?${this.uuidv4()}`

      try {
        const { data } = await axios.get(tracklistURL, {
          headers: {
            'Content-Type': 'application/json',
          }
        })

        this.tracks = JSON.parse(JSON.stringify(data.tracks))
        this.loaded = true
      } catch (error) {
        this.$router.push({ name: 'home' })

        Promise.reject(error)
      }
    },

    loadSelectedTrack(id) {
      this.hasTrackLoaded = false
      const targetTrack = this.tracks.find(_ => _.id === id)
      targetTrack.files.map((file) => {
        const urlPrefix = process.env.VUE_APP_ENV === 'local' ? '/tracks' : 'https://tracks.mybandpage.com'
        file.url = `${urlPrefix}${file.url}`

        return file
      })

      console.log(targetTrack.files)

      this.bufferLoader = new BufferLoader(this.context, targetTrack.files, (bufferList) => {
        let trackTarget = bufferList.find(buffer => buffer.track.name == 'track')
        let clickTarget = bufferList.find(buffer => buffer.track.name == 'click')
        let melodyTarget = bufferList.find(buffer => buffer.track.name == 'melody')

        this.trackSource.buffer = trackTarget.buffer

        if (clickTarget != null) {
          this.clickSource.buffer = clickTarget.buffer
        }

        if (melodyTarget != null) {
          this.melodySource.buffer = melodyTarget.buffer
        }

        this.trackLength = trackTarget.buffer.duration
        this.hasTrackLoaded = true
      })

      this.bufferLoader.load()
    },

    initAudioEngine() {
      this.paused = false
      this.playing = false
      this.startedAt = 0
      this.pausedAt = 0
      this.trackLength = 0
      this.offset = 0
      this.context = new(window.AudioContext || window.webkitAudioContext)()
      this.trackGainNode = this.context.createGain()
      this.clickGainNode = this.context.createGain()
      this.melodyGainNode = this.context.createGain()

      this.trackSource = this.context.createBufferSource()
      this.clickSource = this.context.createBufferSource()
      this.melodySource = this.context.createBufferSource()

      this.trackSource.connect(this.trackGainNode)
      this.clickSource.connect(this.clickGainNode)
      this.melodySource.connect(this.melodyGainNode)

      this.trackGainNode.connect(this.context.destination)
      this.clickGainNode.connect(this.context.destination)
      this.melodyGainNode.connect(this.context.destination)

      this.trackGainNode.gain.value = 1.0

      this.clickEnabled ? this.setClick(3.0) : this.setClick(0.0)
      this.melodyEnabled ? this.setMelody(2.0) : this.setMelody(0.0)

      this.update()
    },

    update() {
      window.requestAnimationFrame(this.update)

      let currentPosition = 0

      if (this.playing) {
        currentPosition = (this.context.currentTime - this.startedAt).toFixed(0)
      } else {
        currentPosition = this.pausedAt.toFixed(0)
      }

      if (currentPosition > this.trackLength) {
        this.resetCurrentTrack()
      }

      this.playbackPositionLabel = `${this.convertToTime(currentPosition)} / ${this.convertToTime(this.trackLength)}`
    },

    resetCurrentTrack() {
      this.stop()
      this.initAudioEngine()
      this.loadSelectedTrack(this.activeTrack)
    },

    convertToTime(seconds) {
      const minutes = (seconds % 3600) / 60
      const minutesDisplay = minutes >= 10 ? `0${Math.floor(minutes)}`.slice(-2) : `${Math.floor(minutes)}`
      const secondsDisplay = `0${Math.floor(seconds % 60)}`.slice(-2)

      return `${minutesDisplay}:${secondsDisplay}`
    },

    play() {
      if (this.playing) {
        return
      }

      if (this.trackSource == null) {
        this.trackSource = this.context.createBufferSource()
        this.clickSource = this.context.createBufferSource()
        this.melodySource = this.context.createBufferSource()

        this.trackSource.connect(this.trackGainNode)
        this.clickSource.connect(this.clickGainNode)
        this.melodySource.connect(this.melodyGainNode)

        this.trackSource.buffer = this.oldTrackBuffer
        this.clickSource.buffer = this.oldClickBuffer
        this.melodySource.buffer = this.oldMelodyBuffer

        this.oldTrackBuffer = null
        this.oldClickBuffer = null
        this.oldMelodyBuffer = null
      }

      this.offset = this.pausedAt

      this.trackSource.start(0, this.offset)
      this.clickSource.start(0, this.offset)
      this.melodySource.start(0, this.offset)

      this.startedAt = this.context.currentTime - this.offset
      this.pausedAt = 0
      this.playing = true
    },

    pause() {
      if (! this.playing) {
        return
      }

      this.elapsed = this.context.currentTime - this.startedAt
      this.stop()
      this.pausedAt = this.elapsed
    },

    stop(startPlayback = false) {
      if (this.trackSource != null) {
        this.trackSource.disconnect()
        this.clickSource.disconnect()
        this.melodySource.disconnect()

        if (this.playing) {
          this.trackSource.stop(0)
          this.clickSource.stop(0)
          this.melodySource.stop(0)
        }

        this.oldTrackBuffer = this.trackSource.buffer
        this.oldClickBuffer = this.clickSource.buffer
        this.oldMelodyBuffer = this.melodySource.buffer

        this.trackSource = null
        this.clickSource = null
        this.melodySource = null
      }

      this.pausedAt = 0
      this.startedAt = 0
      this.playing = false

      if (startPlayback) {
        this.play()
      }
    },

    rewind() {
      this.offset = 0
      this.playing ? this.stop(true) : this.stop()
    },

    setClick(volume) {
      if (volume != this.clickGainNode.gain.value) {
        this.clickGainNode.gain.value = volume
      }

      this.clickEnabled = this.clickGainNode.gain.value > 0
    },

    setMelody(volume) {
      if (volume != this.melodyGainNode.gain.value) {
        this.melodyGainNode.gain.value = volume
      }

      this.melodyEnabled = this.melodyGainNode.gain.value > 0
    }
  },
}
</script>
